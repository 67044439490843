export const environment = {
  production: true,
  mediaEndpoint: 'https://cms.worxs.dk',
  backendEndpoint: 'https://cms.worxs.dk/api',
  apiEndpoint: 'https://api.worxs.dk',
  loginAction: 'https://app.worxs.dk/site/login-with-access-token',
  articleImageLocation: 'top',
  googleTagManagerContainerId: 'GTM-MMT75HH',
  ubivox: {
    worxsdk: 81606
  }
};
